// frontend/src/App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/shared/PrivateRoute';
import AuthSuccess from './pages/Auth/AuthSuccess';
import LoadingSpinner from './components/shared/LoadingSpinner';
import { HeaderProvider } from './contexts/HeaderContext'; // Import HeaderProvider

import ErrorBoundary from './components/common/ErrorBoundary';
//import UploadPage from './pages/Data/UploadPage';
//import FileList from './pages/customer/FileList';

// Lazy load components for better performance
const AdminLogin = lazy(() => import('./pages/Auth/AdminLogin'));
const CustomerLogin = lazy(() => import('./pages/Auth/CustomerLogin'));
const AdminDashboard = lazy(() => import('./pages/admin/AdminDashboard'));
const Customers = lazy(() => import('./pages/admin/Customers'));
const BrandingSettings = lazy(() => import('./pages/admin/BrandingSettings'));
const CustomerDashboard = lazy(() => import('./pages/customer/CustomerDashboard'));
const AudienceExplorer = lazy(() => import('./pages/customer/AudienceExplorer'));
const ProfileUpload = lazy(() => import('./pages/customer/ProfileUpload'));
const UserProfile = lazy(() => import('./pages/user/UserProfile')); // Added import
const NotFound = lazy(() => import('./pages/NotFound'));

const CreativeLibrary = lazy(() => import('./pages/Creatives/ImageLibrary'));
const FileList = lazy(() => import('./pages/Data/FileList'));
const UploadPage = lazy(() => import('./pages/Data/UploadPage'));

const RoleManagement = lazy(() => import('./pages/Settings/RoleManagement'));
const Teammates = lazy(() => import('./pages/Settings/Teammates'));
const AcceptInvitation = lazy(() => import('./pages/Auth/AcceptInvitation'));

// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
const TemplateLibrary = lazy(() => import('./components/Templates/TemplateLibrary'));
const TemplatesList = lazy(() => import('./components/Templates/TemplatesList'));
const TemplateEditor = lazy(() => import('./components/Editor/TemplateEditor'));


const App = () => {
  return (
    <Router>
      <HeaderProvider>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          {/* Authentication Routes */}
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/login" element={<CustomerLogin />} />
          <Route path="/auth/success" element={<AuthSuccess />} />

          {/* Admin Protected Routes */}
          <Route
            path="/admin-dashboard"
            element={
              <PrivateRoute role="admin">
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <PrivateRoute role="admin">
                <Customers />
              </PrivateRoute>
            }
          />
          <Route
            path="/branding/:customerId"
            element={
              <PrivateRoute role="admin">
                <BrandingSettings />
              </PrivateRoute>
            }
          />

          {/* Customer Protected Routes */}
          <Route
            path="/customer-dashboard"
            element={
              <PrivateRoute role="superuser">
                <CustomerDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/audience-explorer"
            element={
              <PrivateRoute role="superuser">
                <AudienceExplorer />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile-upload"
            element={
              <PrivateRoute role="superuser">
                <ProfileUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/profile"
            element={
              <PrivateRoute role="superuser">
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/images"
            element={
              <PrivateRoute role="superuser">
                <CreativeLibrary />
              </PrivateRoute>
            }
          />

        <Route 
          path="/templates/library" 
          element={
            <PrivateRoute role="superuser">
            <TemplateLibrary />
            </PrivateRoute>
          } 
        />

        <Route 
          path="/templates" 
          element={
            <PrivateRoute role="superuser">
            <TemplatesList/>
            </PrivateRoute>
          } 
        />

        <Route 
          path="/templates/create" 
          element={
            <PrivateRoute role="superuser">
            <TemplateEditor/>
            </PrivateRoute>
          } 
        />

        <Route 
          path="/templates/edit/:id" 
          element={
            <PrivateRoute role="superuser">
            <TemplateEditor/>
            </PrivateRoute>
          } 
        />

          <Route 
            path="/customer/files" 
            element={
              <PrivateRoute role="superuser">
                <FileList />
              </PrivateRoute>
            } 
          />

          <Route 
            path="/customer/upload" 
            element={
              <PrivateRoute role="superuser">
                <UploadPage />
              </PrivateRoute>
            } 
          />

        <Route 
          path="/settings/roles" 
          element={
            <PrivateRoute role="superuser">
              <RoleManagement />
            </PrivateRoute>
          } 
        />

        <Route 
          path="/settings/teammates" 
          element={
            <PrivateRoute role="superuser">
              <Teammates />
            </PrivateRoute>
          } 
        />

        <Route path="/accept-invite/:token" element={<AcceptInvitation />} />

          {/* 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      </HeaderProvider>
    </Router>

  );
};

export default App;
