// frontend/src/components/admin/AdminSideNav.js
import React from 'react';
import { Menu } from 'antd';
import {
  DashboardOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
} from '@ant-design/icons';

import { useMenuHandlers } from '../../utils/menuHandlers';

const AdminSideNav = () => {

  const { handleMenuClick } = useMenuHandlers();

  // Define the menu items array
  const items = [
    {
      key: '/admin-dashboard',
      icon: <DashboardOutlined />,
      label: 'Dashboard',
    },
    {
      key: '/customers',
      icon: <UsergroupAddOutlined />,
      label: 'Customers',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
    },
  ];

  // Wrap the handleMenuClick function to pass the 'admin' userType
  const onMenuClick = (info) => {
    handleMenuClick(info, 'admin');
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      onClick={onMenuClick} // Attach the wrapped click handler to the menu
      items={items} // Pass the items array to the Menu
    />
  );
};

export default AdminSideNav;
