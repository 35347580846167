// frontend/src/components/shared/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import AdminLayout from '../admin/AdminLayout';
import CustomerLayout from '../customer/CustomerLayout';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ children, role }) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const userRole = decodedToken.role;

    // Check if token is expired
    if (decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem('accessToken');
      return <Navigate to="/login" replace />;
    }

    // Normalize role to an array
    const roles = Array.isArray(role) ? role : [role];

    // Check if user has required role
    if (roles && !roles.includes(userRole)) {
      return <Navigate to="/unauthorized" replace />;
    }

    // For customer roles
    if (userRole === 'superuser' || userRole === 'user') {
      return <CustomerLayout>{children}</CustomerLayout>;
    }

    // For admin role
    if (userRole === 'admin') {
      return <AdminLayout>{children}</AdminLayout>;
    }

    return <Navigate to="/unauthorized" replace />;
  } catch (error) {
    localStorage.removeItem('accessToken');
    return <Navigate to="/login" replace />;
  }
};

export default PrivateRoute;
