// frontend/src/components/customer/CustomerHeader.js

import React, { useState } from 'react';
import { Button, Tooltip, Input, Dropdown, Typography, Space, Badge } from 'antd';
import {
  LeftOutlined,
  SearchOutlined,
  DownOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './customerHeader.css';

const { Title } = Typography;

const CustomerHeader = ({
  title,
  primaryAction,
  secondaryActions = [],
  onSearch,
  onFilter,
  isFilterApplied, // New prop to indicate if filters are applied
  onBack,
}) => {
  const navigate = useNavigate();
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  // Handle back navigation
  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  // Toggle search input visibility
  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  // Render primary action with tooltip if provided
  const renderPrimaryAction = () => {
    if (!primaryAction) return null;

    let button;

    if (primaryAction.menu) {
      button = (
        <Dropdown menu={primaryAction.menu} trigger={['click']}>
          <Button
            type="primary"
            icon={primaryAction.icon}
            className="primary-action-button"
            disabled={primaryAction.disabled}
          >
            {primaryAction.label} <DownOutlined />
          </Button>
        </Dropdown>
      );
    } else {
      button = (
        <Button
          type="primary"
          icon={primaryAction.icon}
          onClick={primaryAction.onClick}
          className="primary-action-button"
          disabled={primaryAction.disabled}
        >
          {primaryAction.label}
        </Button>
      );
    }

    if (primaryAction.tooltip) {
      return <Tooltip title={primaryAction.tooltip}>{button}</Tooltip>;
    }

    return button;
  };

  return (
    <div className="customer-header">
      <div className="header-left">
        {/* Back Arrow */}
        {onBack && (
          <Tooltip title="Back">
            <Button
              type="text"
              icon={<LeftOutlined />}
              onClick={handleBack}
              className="back-button"
            />
          </Tooltip>
        )}

        {/* Title */}
        {title && (
          <Title level={4} className="header-title">
            {title}
          </Title>
        )}
      </div>

      <div className="header-right">
        <Space>
          {/* Search Component */}
          {searchVisible ? (
            <Input
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchChange}
              onBlur={() => setSearchVisible(false)}
              autoFocus
              className="search-input"
            />
          ) : (
            onSearch && (
              <Tooltip title="Search">
                <Button
                  type="text"
                  icon={<SearchOutlined />}
                  onClick={toggleSearch}
                  className="search-button"
                />
              </Tooltip>
            )
          )}

          {/* Filter Icon with Highlight when Filters are Applied */}
          {onFilter && (
            <Tooltip title="Filter">
              <Badge dot={isFilterApplied}>
                <Button
                  type="text"
                  icon={<FilterOutlined />}
                  onClick={onFilter}
                  className="filter-button"
                />
              </Badge>
            </Tooltip>
          )}

          {/* Secondary Actions */}
          {secondaryActions &&
          secondaryActions.map((action, index) => (
            <Tooltip title={action.tooltip} key={index}>
              <Badge dot={action.highlight}>
                <Button
                  type="text"
                  icon={action.icon}
                  onClick={action.onClick}
                  loading={action.loading}
                  style={{ marginLeft: '8px' }}
                />
              </Badge>
            </Tooltip>
          ))}

          {/* Primary Action */}
          {renderPrimaryAction()}
        </Space>
      </div>
    </div>
  );
};

export default React.memo(CustomerHeader);