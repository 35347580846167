// frontend/src/components/common/ErrorBoundary.js

import React from 'react';
import { Result, Button } from 'antd';

/**
 * ErrorBoundary Component
 * 
 * Catches JavaScript errors anywhere in its child component tree,
 * logs those errors, and displays a fallback UI.
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  handleReload = () => {
    // Optionally, log the error to an error reporting service
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return (
        <Result
          status="error"
          title="Something went wrong."
          subTitle="We're sorry for the inconvenience. Please try refreshing the page."
          extra={[
            <Button type="primary" onClick={this.handleReload} key="reload">
              Reload Page
            </Button>,
          ]}
        />
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
