// frontend/src/pages/Auth/AuthSuccess.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      localStorage.setItem('accessToken', token);
      navigate('/customer-dashboard');
    } else {
      navigate('/login');
    }
  }, [navigate]);

  return null;
};

export default AuthSuccess;
