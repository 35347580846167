// frontend/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'antd/dist/reset.css'; // Ant Design styles
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome styles
import './App.css'; // Your custom styles
import { UploadProvider } from './UploadContext'; // Import UploadProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UploadProvider>
      <App />
    </UploadProvider>
  );
