// frontend/src/components/admin/AdminHeader.js
import React from 'react';
import { Button, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useMenuHandlers } from '../../utils/menuHandlers';

const AdminHeader = () => {

  // Handle menu item clicks (logout handling)
  const { handleMenuClick } = useMenuHandlers();

  // Define menu items using the `items` property
  const items = [
    {
      key: '/users/profile',
      label: 'Profile',
    },
    {
      key: 'logout',
      label: 'Logout',
    },
  ];

  // Wrap the handleMenuClick function to include the userType as 'admin'
  const onMenuClick = (info) => {
    handleMenuClick(info, 'admin');
  };

  return (
    <div style={{ float: 'right', paddingRight: '16px' }}>
      <Dropdown
        menu={{
          items,
          onClick: onMenuClick, // Attach the wrapped click handler to the menu
        }}
        placement="bottomRight"
      >
        <Button icon={<UserOutlined />}>Admin</Button>
      </Dropdown>
    </div>
  );
};

export default AdminHeader;
