// frontend/src/components/customer/CustomerLayout.js

import React, { useContext, useState } from 'react';
import { Layout } from 'antd';
import CustomerNav from './CustomerNav';
import CustomerHeader from './CustomerHeader';
import HeaderContext from '../../contexts/HeaderContext';
import { CustomerProvider } from '../../contexts/CustomerContext';
import './CustomerLayout.css'; // Import the new CSS file

const { Header, Content } = Layout;

const CustomerLayout = ({ children }) => {
  const { headerContent } = useContext(HeaderContext);
  const [collapsed, setCollapsed] = useState(true);

  const defaultHeaderContent = {
    title: '',
    primaryAction: null,
    secondaryActions: [],
    onSearch: null,
    onBack: null,
  };

  const finalHeaderContent = { ...defaultHeaderContent, ...headerContent };

  const handleCollapseToggle = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };

  const siderWidth = collapsed ? 80 : 250;
  const headerHeight = 64;

  return (
    <CustomerProvider>
      <Layout>
        <CustomerNav collapsed={collapsed} onCollapseToggle={handleCollapseToggle} />
        <Layout
          className="customer-inner-layout"
          style={{ marginLeft: siderWidth }}
        >
          <Header
            className="layout-header"
            style={{
              left: siderWidth,
              height: headerHeight,
              padding: 0,
            }}
          >
            <CustomerHeader {...finalHeaderContent} />
          </Header>
          <Content
            className="customer-content"
            style={{
              marginTop: headerHeight,
              height: `calc(100vh - ${headerHeight}px)`,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </CustomerProvider>
  );
};

export default CustomerLayout;
