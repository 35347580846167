// src/utils/menuHandlers.js
import { message } from 'antd';
import api from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { removeTokens } from '../utils/tokenManager';

export const useMenuHandlers = () => {
  const navigate = useNavigate();

  const handleMenuClick = async ({ key }, userType = 'admin') => {
    if (key === 'logout') {
      try {
        // Clear session on the server
        await api.post('/api/auth/logout');

        // Clear tokens using a token management utility
        removeTokens(userType);

        // Display success message
        message.success('Logged out successfully');

        // Navigate based on user type
        if (userType === 'customer') {
          navigate('/login');
        } else {
          navigate('/admin');
        }
      } catch (error) {
        console.error('Logout error:', error);

        // Extracting meaningful error message (if available)
        const errorMsg = error.response?.data?.message || 'Failed to logout. Please try again.';
        message.error(errorMsg);
      }
    } else {
      // Map specific menu keys to routes
      const routeMap = {
        profile: '/users/profile',
        // Add other key-route mappings here as needed
      };

      // Check if the clicked key exists in routeMap
      if (routeMap[key]) {
        navigate(routeMap[key]);
      } else {
        // Default navigation behavior
        navigate(key);
      }
    }
  };

  return { handleMenuClick };
};
