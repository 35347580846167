// frontend/src/components/admin/AdminLayout.js
import React from 'react';
import { Layout } from 'antd';
import AdminSideNav from './AdminSideNav';
import AdminHeader from './AdminHeader';

const { Header, Content, Footer, Sider } = Layout;

const AdminLayout = ({ children }) => {
    console.log('Rendering AdminLayout');
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <div className="logo">Singleview Admin</div>
        <AdminSideNav />
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0 }}>
          <AdminHeader />
        </Header>
        <Content style={{ margin: '16px' }}>
          <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Singleview ©2024</Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
