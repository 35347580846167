// frontend/src/components/customer/CustomerNav.js

import React, { useState, useContext } from 'react';
import {
  Layout,
  Avatar,
  Drawer,
  Typography,
  Input,
  Dropdown,
  message,
  Tooltip,
  Menu,
  Divider,
} from 'antd';
import {
  SettingOutlined,
  UserOutlined,
  TeamOutlined,
  RocketOutlined,
  AppstoreOutlined,
  ExperimentOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  BarChartOutlined,
  KeyOutlined,
  BellOutlined,
  TagsOutlined,
  CodeOutlined,
  SwapOutlined,
  ProfileOutlined,
  HomeOutlined,
  PictureOutlined,
  StarOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/axiosConfig';
import { useMenuHandlers } from '../../utils/menuHandlers';
import './CustomerNav.css';
import CustomerContext from '../../contexts/CustomerContext';

const { Sider } = Layout;
const { Text } = Typography;

const CustomerNav = ({ collapsed, onCollapseToggle }) => {
  const { handleMenuClick } = useMenuHandlers();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);
  const [isSettingsMenu, setIsSettingsMenu] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // Use context to get customer and user data
  const { customerName, customerLogo, userName, userEmail } = useContext(CustomerContext);

  // Toggle collapsed state
  const toggleCollapse = () => {
    onCollapseToggle(!collapsed);
  };

  // Ensure only one submenu is open at a time
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  // Toggle between Main Menu and Settings Menu
  const toggleSettingsMenu = () => {
    setIsSettingsMenu(!isSettingsMenu);
    setOpenKeys([]);
  };

  // Toggle AI Assistant drawer
  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  // Handle 'Return to Admin' action
  const handleReturn = async () => {
    try {
      const res = await api.post('/api/proxy/return');
      const { token } = res.data;

      // Replace 'accessToken' with admin token
      localStorage.setItem('accessToken', token);

      // Remove 'adminAccessToken' since we're back to admin
      localStorage.removeItem('adminAccessToken');

      message.success('Returned to admin account');

      // Redirect to admin dashboard
      navigate('/admin-dashboard');
    } catch (error) {
      console.error('Return error:', error);
      message.error('Failed to return to admin account');
    }
  };

  // Check if the admin is proxied
  const isAdminProxied = !!localStorage.getItem('adminAccessToken');

  // Handle menu clicks
  const onMenuClick = (info) => {
    const { key } = info;
    if (key === 'toggle-settings') {
      toggleSettingsMenu();
    } else if (key === 'ai-assistant') {
      toggleDrawer();
    } else if (key === '/return') {
      handleReturn();
    } else if (['assistants', 'models', 'apps', 'composer','plans','experiments','builder','events','audience-explorer','profile-upload','segments','fragments','transformer','feeds','products','coupons','analytics','predictions','account','keys','channels','connectors','tags','sdks','notifications','ai-studio-settings','campaigns-settings','flows-settings','audience-settings','creatives-settings','data-settings','intelligence-settings'].includes(key)) {
      message.info('This feature is not yet implemented.');
    } else {
      handleMenuClick(info, 'customer');
    }
    if (collapsed) {
      onCollapseToggle(false);
    }
  };

  // Main Navigation Menu
  const mainMenuItems = [
    { key: '/customer-dashboard', label: 'Mission Control', icon: <RocketOutlined /> },
    {
      key: 'ai-studio',
      label: 'AI Studio',
      icon: <AppstoreOutlined />,
      children: [
        { key: 'assistants', label: 'Assistants' },
        { key: 'models', label: 'Models' },
        { key: 'apps', label: 'Apps' },
      ],
    },
    {
      key: 'campaigns',
      label: 'Campaigns',
      icon: <ExperimentOutlined />,
      children: [
        { key: 'composer', label: 'Composer' },
        { key: 'plans', label: 'Plans' },
        { key: 'experiments', label: 'Experiments' },
      ],
    },
    {
      key: 'flows',
      label: 'Flows',
      icon: <ClusterOutlined />,
      children: [
        { key: 'builder', label: 'Builder' },
        { key: 'events', label: 'Events' },
      ],
    },
    {
      key: 'audience',
      label: 'Audience',
      icon: <TeamOutlined />,
      children: [
        { key: '/audience-explorer', label: 'Explorer' },
        { key: '/profile-upload', label: 'Identities' },
        { key: 'segments', label: 'Segments' },
      ],
    },
    {
      key: 'creatives',
      label: 'Creatives',
      icon: <PictureOutlined />,
      children: [
        { key: '/templates/library', label: 'Library' },
        { key: '/templates', label: 'Templates' },
        { key: '/images', label: 'Images' },
        { key: 'fragments', label: 'Fragments' },
      ],
    },
    {
      key: 'data',
      label: 'Data',
      icon: <DatabaseOutlined />,
      children: [
        { key: 'transformer', label: 'Transformer' },
        { key: '/customer/files', label: 'Files' },
        { key: 'feeds', label: 'Feeds' },
        { key: 'products', label: 'Products' },
        { key: 'coupons', label: 'Coupons' },
      ],
    },
    {
      key: 'intelligence',
      label: 'Intelligence',
      icon: <BarChartOutlined />,
      children: [
        { key: 'analytics', label: 'Analytics' },
        { key: 'predictions', label: 'Predictions' },
      ],
    },

  ];

  // Settings Navigation Menu
  const settingsMenuItems = [
    { key: '/users/profile', label: 'My Profile', icon: <UserOutlined /> },
    { key: 'account', label: 'Account', icon: <ProfileOutlined /> },
    { key: '/settings/teammates', label: 'Team', icon: <TeamOutlined /> },
    { key: '/settings/roles', label: 'Roles', icon: <UserOutlined /> },
    {
      key: 'integrations',
      label: 'Integrations',
      icon: <SwapOutlined />,
      children: [
        { key: 'keys', label: 'Keys', icon: <KeyOutlined /> },
        { key: 'channels', label: 'Channels', icon: <AppstoreOutlined /> },
        { key: 'connectors', label: 'Connectors', icon: <ClusterOutlined /> },
        { key: 'tags', label: 'Tags', icon: <TagsOutlined /> },
        { key: 'sdks', label: 'SDKs', icon: <CodeOutlined /> },
      ],
    },
    { key: 'notifications', label: 'Notifications', icon: <BellOutlined /> },
    {
      key: 'product-settings',
      label: 'Product Settings',
      icon: <SettingOutlined />,
      children: [
        { key: 'ai-studio-settings', label: 'AI Studio', icon: <AppstoreOutlined /> },
        { key: 'campaigns-settings', label: 'Campaigns', icon: <ExperimentOutlined /> },
        { key: 'flows-settings', label: 'Flows', icon: <ClusterOutlined /> },
        { key: 'audience-settings', label: 'Audience', icon: <TeamOutlined /> },
        { key: 'creatives-settings', label: 'Creatives', icon: <ProfileOutlined /> },
        { key: 'data-settings', label: 'Data', icon: <DatabaseOutlined /> },
        { key: 'intelligence-settings', label: 'Intelligence', icon: <BarChartOutlined /> },
      ],
    },
  ];

  // Footer Menu Items
  const footerMenuItems = [
    {
      key: 'toggle-settings',
      label: isSettingsMenu ? 'Back to Main Menu' : 'Settings',
      icon: isSettingsMenu ? <HomeOutlined /> : <SettingOutlined />,
      onClick: toggleSettingsMenu,
    },
    {
      key: 'ai-assistant',
      label: 'AI Assistant',
      icon: <StarOutlined />,
      onClick: toggleDrawer,
    },
  ];

  // Flyout menu for the logged-in user
  const userMenuItems = [
    { key: 'profile', label: 'My Profile', icon: <UserOutlined /> },
    ...(isAdminProxied
      ? [{ key: '/return', label: 'Return to Admin', icon: <HomeOutlined /> }]
      : []),
    { key: 'logout', label: 'Logout', icon: <LogoutOutlined />, style: { color: 'red' } },
  ];

  // Determine Sider width based on collapsed state
  const siderWidth = collapsed ? 80 : 250;

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapse}
        width={250}
        trigger={null}
        className={`custom-sider ${collapsed ? 'collapsed' : ''} ${
          isSettingsMenu ? 'settings-menu' : 'main-menu'
        }`}
        breakpoint="lg"
        collapsedWidth={80}
        style={{
          position: 'fixed',
          left: 0,
          top: 0,
          height: '100vh',
          zIndex: 1000,
          transition: 'width 0.2s',
        }}
      >
        {/* Top Logo/Customer Name */}
        <div className="nav-header" onClick={toggleCollapse}>
          {customerLogo ? (
            <Avatar
              size="large"
              src={customerLogo}
              shape="square"
              className="customer-avatar"
            />
          ) : (
            <Avatar size="large" shape="square" className="customer-avatar">
              {customerName.charAt(0)}
            </Avatar>
          )}
          {!collapsed && (
            <Tooltip title={customerName}>
              <Text ellipsis className="customer-name">
                {customerName}
              </Text>
            </Tooltip>
          )}
        </div>

        {/* Divider below the top logo/customer name */}
        <Divider className="nav-divider" />

        {/* Menu Container */}
        <div className="menu-container">
          <Menu
            theme={isSettingsMenu ? 'light' : 'dark'}
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            onClick={onMenuClick}
            items={isSettingsMenu ? settingsMenuItems : mainMenuItems}
            className="main-menu"
          />
        </div>

        {/* Bottom Menu */}
        <div className="bottom-menu">
          {/* Divider above the bottom menu */}
          <Divider className="nav-divider" />

          {/* Footer Items */}
          <div className="footer-items">
            {footerMenuItems.map((item) => (
              <div
                key={item.key}
                className={`footer-item ${collapsed ? 'collapsed' : ''} ${
                  isSettingsMenu ? 'settings-menu' : 'main-menu'
                }`}
                onClick={item.onClick}
              >
                <span className="footer-item-icon">{item.icon}</span>
                <span className="footer-item-label">{!collapsed && item.label}</span>
              </div>
            ))}
          </div>

          {/* Divider above the user menu */}
          <Divider className="nav-divider" />

          {/* User Thumbnail and Flyout Menu */}
          <div
            className={`user-menu ${collapsed ? 'collapsed' : ''} ${
              isSettingsMenu ? 'settings-menu' : 'main-menu'
            }`}
          >
            <Dropdown
              overlay={<Menu onClick={onMenuClick} items={userMenuItems} />}
              trigger={['click']}
              placement="topCenter"
            >
              <div className="user-menu-item">
                <Avatar size="small">
                  {userEmail ? userEmail.charAt(0).toUpperCase() : userName.charAt(0)}
                </Avatar>
                <span className="user-name">{!collapsed && userName}</span>
              </div>
            </Dropdown>
          </div>
        </div>
      </Sider>

      {/* AI Assistant Drawer */}
      <Drawer
        title="AI Assistant"
        placement="left"
        onClose={toggleDrawer}
        open={isDrawerOpen}
        mask={false}
        width={300}
        className="ai-drawer"
        style={{ position: 'fixed', left: siderWidth, top: 0 }}
        getContainer={false}
      >
        <p>Ask me anything, I am here to help!</p>
        <Input placeholder="Type here..." />
      </Drawer>
    </>
  );
};

export default React.memo(CustomerNav);
