// frontend/src/contexts/CustomerContext.js

import React, { createContext, useState, useEffect } from 'react';
import api from '../utils/axiosConfig';

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [customerName, setCustomerName] = useState('Customer');
  const [customerLogo, setCustomerLogo] = useState(null);
  const [userName, setUserName] = useState('User');
  const [userEmail, setUserEmail] = useState('user@example.com');

  useEffect(() => {
    // Fetch customer data once
    const fetchCustomerData = async () => {
      try {
        const response = await api.get('/api/customers/me');
        const customerData = response.data;
        setCustomerName(customerData.name || 'Customer');
        setCustomerLogo(customerData.logo || null);
      } catch (error) {
        console.error('Error fetching customer data:', error);
      }
    };

    // Fetch user data once
    const fetchUserData = async () => {
      try {
        const response = await api.get('/api/users/me');
        const userData = response.data;
        setUserName(userData.name || 'User');
        setUserEmail(userData.email || 'user@example.com');
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchCustomerData();
    fetchUserData();
  }, []);

  return (
    <CustomerContext.Provider value={{ customerName, customerLogo, userName, userEmail }}>
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerContext;
